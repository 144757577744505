.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#tsparticles {
  height: 100%;
}

body {
  overflow-x: hidden;
}

.apikey {
  height: 40px;
  background-color: rgba(13, 72, 160, 0.5);
  border-radius: 10px;
  align-self: center;
  font-size: 20px;
  line-height: 2;
  padding: 10px 0px;
}
.apikey:hover {
  cursor: pointer;
}

.MuiButton-containedPrimary {
  background-color: #7599ff !important;
}
.MuiButton-containedPrimary:hover {
  background-color: #587be7 !important;
}

.MuiButton-contained {
  border-radius: 100px !important; /* just needs to be larger than the height of the button */
}

.unsubscribe-button:hover {
  background-color: #e5e9f4 !important;
}

.MuiPaper-rounded {
  border-radius: 4px;
  background-color: #fff;
}

.MuiButton-root {
  text-transform: none !important;
}
.MuiIconButton-edgeEnd {
  margin-right: 0px !important; /* why did material-ui add this? */
}
.MuiTab-root {
  text-transform: none !important;
  font-weight: bold !important;
  font-family: "Open sans" !important;
}

.text-button {
  color: #fff !important;
  font-weight: 700 !important;
  transition: color 0.15s linear !important;
}
.text-button:hover {
  color: #577ce7 !important;
}

.organisation-select .MuiAutocomplete-input {
    padding-right: 0px !important;
    padding-top: 6px !important;
    padding-bottom: 7px !important;
    color: white;
}

.organisation-select .MuiAutocomplete-inputRoot {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.model-summary:hover {
  background-color: #f0f4fd !important;
}

.MuiAutocomplete-inputRoot > input {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}

.subscription-accesslist-select {
    width: 208px !important;
}
